
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Gender from "@/components/reusable/Gender.vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "admin-add-new-individual-client",
  components: { ErrorMessage, Field, Form, Gender },
  data() {
    return {
      passwordVisible: false,
      displayShowPasswword: false,
      roles: [],
      avatar: "",
      emailAddress: "",
      firstName: "",
      gender: "",
      lastName: "",
      maritalStatus: "",
      middleName: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      role: "dcc64926-6bf0-41c6-bb33-ea0f23b5ee29",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const newMemberDetailsValidator = Yup.object().shape({
      firstName: Yup.string()
        .required()
        .label("First name"),
      middleName: Yup.string()
        .notRequired()
        .label("First name"),
      lastName: Yup.string()
        .required()
        .label("Last name"),
      emailAddress: Yup.string()
        .required()
        .label("Email address"),
      phoneNumber: Yup.number()
        .required()
        .label("Phone number"),
      gender: Yup.mixed()
        .required()
        .label("Gender"),
      maritalStatus: Yup.mixed()
        .required()
        .label("Martial status"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
      confirmPassword: Yup.string()
        .min(8)
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Confirm Password"),
    });

    const createIndividualClient = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.post(variables.SUPERADMIN_USER_ROUTE, values)
              .then((response) => {
                variables.toastAlert(
                  "User was created successfully.",
                  "success"
                );
                resetForm();
                router.push({
                  name: "admin-users-individual-clients",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Add New", ["Users", "Individual Clients"]);
    });

    return {
      submitButton1,
      createIndividualClient,
      newMemberDetailsValidator,
    };
  },
  created() {
    //Set page title
    document.title = "Add New Individual Client | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    removeImage() {
      this.avatar = "../media/avatars/blank.png";
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
    handleImageUpload(event) {
      const selectedImage = event.target.files[0]; //get the first file

      const reader = new FileReader();
      reader.readAsDataURL(selectedImage);

      reader.onload = (e) => {
        this.avatar = reader.result as string;
      };

      reader.onerror = function(error) {
        variables.toastAlert(error, "error");
      };
    },
    generatePassword() {
      const passwordField = document.getElementById(
        "password"
      ) as HTMLInputElement;
      const confirmPasswordField = document.getElementById(
        "confirm-password"
      ) as HTMLInputElement;

      const generatedPassword = variables.generatePassword();
      passwordField.value = generatedPassword;
      confirmPasswordField.value = generatedPassword;
    },
    togglePasswordVisibility() {
      this.displayShowPasswword = this.password ? true : false;
      this.passwordVisible = !this.passwordVisible;
    },
  },
});
